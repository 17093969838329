html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #f6f3f2;
  text-align: center;
}


.inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 100vw;
  height: 100vh;
}

.top-bar {
  width: 100%;
  background-color: #ffffff;
  padding: 10px 0px;

  @media (min-width: 375px) {
    padding: 12px 0px;
  }
  @media (min-width: 450px) {
    padding: 20px 0px;
  }
  @media (min-width: 900px) {
    padding: 30px 0px;
  }

  .sub-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    text-align: center;
    color: #000000;

    @media (min-width: 375px) {
      font-size: 20px;
      line-height: 25px;
    }
    @media (min-width: 450px) {
      font-size: 22px;
      line-height: 27px;
    }
    @media (min-width: 900px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.charger-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 12px 0px 0px;
  @media (min-width: 375px) {
    padding: 13px 0px 0px;
  }
  @media (min-width: 450px) {
    padding: 15px 0px 0px;
  }
  @media (min-width: 900px) {
    padding: 20px 0px 0px;
  }

  width: 100vw;

  .station {
    padding: 12px;
    width: 100vw;
    background: #ffffff;

    .sub-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 2px;
      @media (min-width: 375px) {
        gap: 3px;
      }
      @media (min-width: 450px) {
        gap: 5px;
      }
      @media (min-width: 900px) {
        gap: 10px;
      }

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      @media (min-width: 375px) {
        font-size: 17px;
        line-height: 22px;
      }
      @media (min-width: 450px) {
        font-size: 19px;
        line-height: 24px;
      }
      @media (min-width: 900px) {
        font-size: 23px;
        line-height: 28px;
      }
      color: #999999;
    }

    .main-text {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
    }
  }

  .station-info {
    display: flex;
    width: 100vw;
    background: #ffffff;
  }


}

.reload-text {
  width: 100%;
  text-align: center;
}

.charger-action-wrap {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  padding: 10px 16px 50px;
  @media (min-width: 375px) {
    gap: 10px;
    padding: 14px 16px 50px;
  }
  @media (min-width: 450px) {
    gap: 14px;
    padding: 16px 16px 50px;
  }
  @media (min-width: 900px) {
    gap: 20px;
    padding: 20px 16px 50px;
  }

  background: #f6f3f2;


  .sub-text {
    width: 100%;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    @media (min-width: 375px) {
      font-size: 17px;
      line-height: 22px;
    }
    @media (min-width: 450px) {
      font-size: 20px;
      line-height: 25px;
    }
    @media (min-width: 900px) {
      font-size: 30px;
      line-height: 35px;
    }

    color: #002c5f;

    .strong {
      font-weight: 700;
    }
  }

  .button-wrap {
    width: 100%;
    background: #002c5f;
    padding: 10px 0px;
    font-size: 17px;
    line-height: 22px;
    cursor: pointer;

    @media (min-width: 375px) {
      padding: 15px 0px;
      font-size: 17px;
      line-height: 22px;
    }
    @media (min-width: 450px) {
      padding: 18px 0px;
      font-size: 20px;
      line-height: 25px;
    }
    @media (min-width: 900px) {
      padding: 25px 0px;
      font-size: 25px;
      line-height: 30px;
    }

    border: none;
    font-style: normal;
    font-weight: 400;
    color: #ffffff;
  }

  .disabled {
    background: #CCCCCC;
  }

}

.status-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  .action-wrap {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 16px 20px;
    gap: 8px;

    .sub-text {
      width: 100%;
      text-align: center;

      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #002c5f;

      .strong {
        font-weight: 700;
      }
    }

    background: #f6f3f2;

    .btn-wrap {
      width: 100%;
      background: #002c5f;
      padding: 13px 0px;
      font-size: 17px;
      line-height: 22px;

      @media (min-width: 375px) {
        padding: 15px 0px;
        font-size: 17px;
        line-height: 22px;
      }
      @media (min-width: 450px) {
        padding: 18px 0px;
        font-size: 20px;
        line-height: 25px;
      }
      @media (min-width: 900px) {
        padding: 25px 0px;
        font-size: 25px;
        line-height: 30px;
      }

      border: none;
      font-style: normal;
      font-weight: 400;
      color: #ffffff;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .img-wrap {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        @media (min-width: 375px) {
          width: 23px;
          height: 23px;
        }
        @media (min-width: 450px) {
          width: 25px;
          height: 25px;
        }
        @media (min-width: 900px) {
          width: 30px;
          height: 30px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.default-btn-wrap {
  width: 100%;
  height: 100%;
  background: #002c5f;
  padding: 5px 0px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #ffffff;
  border: none;


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.modal-wrap {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);

  .fade-in {
    animation: fade-in 0.2s ease-in-out forwards;
  }

  .fade-out {
    animation: fade-out 0.2s ease-in-out forwards;
  }

  .modal {
    width: 90%;
    border-radius: 4px;
    background-color: #fff;

    .title {
      padding: 20px 20px 0;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      @media (min-width: 375px) {
        font-size: 20px;
        line-height: 25px;
      }
      @media (min-width: 450px) {
        font-size: 22px;
        line-height: 27px;
      }
      @media (min-width: 900px) {
        font-size: 25px;
        line-height: 30px;
      }
    }

    .content {
      padding: 20px 20px 0;
      overflow-y: auto;
      max-height: calc(100vh - 200px);
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      @media (min-width: 375px) {
        font-size: 15px;
        line-height: 20px;
      }
      @media (min-width: 450px) {
        font-size: 17px;
        line-height: 22px;
      }
      @media (min-width: 900px) {
        font-size: 20px;
        line-height: 25px;
      }

      color: #1a1a1a;
    }

    .button-wrap {
      padding: 20px 20px;
      text-align: right;
      cursor: pointer;
    }

    .button-double-wrap {
      margin: 20px 0px;
      display: flex;
      justify-content: center;
      width: 100%;

      .cancel-box {
        padding: 0px 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #1a1a1a;
        width: 100%;
        cursor: pointer;
      }

      .confirm-box {
        padding: 0px 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #1a1a1a;
        width: 100%;
        cursor: pointer;
      }
    }

    .button {
      padding: 0px 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      @media (min-width: 375px) {
        font-size: 18px;
        line-height: 23px;
      }
      @media (min-width: 450px) {
        font-size: 20px;
        line-height: 25px;
      }
      @media (min-width: 900px) {
        font-size: 23px;
        line-height: 28px;
      }
      color: #1a1a1a;
    }
  }
}

.table-container {
  border: 1px solid #ddd;

  .MuiTableCell-head {
    background-color: #f7f7f7;
    text-align: center;
  }

  .MuiTableCell-body {
    text-align: center;
  }

  .fail {
    color: red;
  }
}

.formTable-container {
  border: 1px solid #ddd;
  .cell-title {
    background-color: #f3f6f9;
    text-align: center;
    text-align: left;
  }
}

.footer {
  position: fixed;
  margin-top: 0px;
  margin-bottom: 0px;
  bottom: 0px;
  width: 100%;
  left: 0px;
  background: #000000;
  color: #ffffff;
  .one {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .double {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
  }
  .button-wrap {
    display: flex;
    justify-content: center;
    align-items:center;
    gap: 5px;
    cursor: pointer;

    width: 100%;
    padding: 10px 0px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: rgba(255,171,0,0);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    font-weight: 500;
    font-size: 10px;
    line-height: 21px;
    @media (min-width: 375px) {
      font-size: 13px;
      line-height: 23px;
    }
    @media (min-width: 450px) {
      font-size: 15px;
      line-height: 25px;
    }
    @media (min-width: 900px) {
      font-size: 17px;
      line-height: 28px;
    }
  }
}

.short {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

